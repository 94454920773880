<template>
  <div>
    <h1 style="font-weight: 700; margin-bottom: 30px">{{ item.title }}</h1>
    <p>
      por <strong>{{ item.authorName }}</strong> em {{formatDate(item.createDate)}}
    </p>
    <b-row>
      <b-col sm="8">
        <div id="contentBody"  class="ql-container ql-snow ql-editor" v-html="item.contentBody" />
      </b-col>
      <b-col sm="4" class="coluna-menu-rolagem">
        <b-card id="cardFixed"> 
          <div class="mb-2">
            <a href="/blog"><b-icon icon="arrow-left" /> Voltar</a>
          </div>
          <h2>{{ item.title }}</h2>
          <divider-line :space="2" />
          <ul>
            <li v-for="(m, i) in items" :key="i" class="mb-1">
              <a :href="`/blog-view/${m.id}`">
                {{ m.title }}
              </a>
            </li>
          </ul>
        </b-card>
      </b-col>
    </b-row>
    <divider-line />
  </div>
</template>

<script>
import { BRow, BCol, BCard, BIcon } from 'bootstrap-vue'

import BlogService from '@/services/BlogService'
import DividerLine from '@/components/DividerLine.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    DividerLine,
    BIcon,
    BCard,
    quillEditor,
    BRow,
    BCol,
  },

  props: ['item'],

  data() {
    return {
      items: [],
    }
  },

  computed: {
    itemsOthers() {
      return this.items.slice(4)
    },
  },

  mounted() {
    this.loadItems()   
    window.addEventListener('scroll', this.handleScroll)
  },

  methods: {
    async loadItems() {
      const { items } = (await BlogService.getItemListPublic('MaxResultCount=3')).data.result
      this.items = items.map(m => ({ ...m.blogItem, blogCategoryName: m.blogCategoryName, blogStatusName: m.blogStatusName }))
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return new Intl.DateTimeFormat('pt-BR').format(date)
    },
    handleScroll(){
      let nav = document.getElementById('cardFixed')
      let contentBody = document.getElementById('contentBody')
      let percent = contentBody.clientHeight * 0.08
      if (window.scrollY > contentBody.clientHeight - percent) { 
          nav.className = 'card menu-rolagem-bottom'
      } else if (window.scrollY > 250){ 
          nav.className = 'card menu-fixo'
      } else {
          nav.className = 'card'
      }
    }
  },
}
</script>
<style lang="scss">
 #contentBody.ql-container.ql-snow {
   border: none;
 }
 #contentBody p{
   font-size: 1.3em;
 } 
 #contentBody blockquote{
   font-size: 1.3em;
   border-left: 4px solid #ccc;
   padding-left: 16px;
 }
 .card {
    min-width: 25em !important;
 }
 .menu-fixo {
    position: fixed;
    top: 10em;
    max-width: 20em  !important;
    transition: all 0s, z-index 0s;
 }
 .menu-rolagem-bottom {
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 25em !important;
    word-wrap: break-word;
    transition: all 0s, z-index 0s;
    bottom: 0;
 }

 @media (max-width: 800px) {
  .coluna-menu-rolagem {
    display: none;
 }
 }
</style>