<style scoped>

.banner {
  height: 350px;
  border-radius: 8px;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center;
}

.path {
  padding: 0;
  display: inline;
  list-style-type: none;
}

.path li {
  display: inline;
}

.sep {
  margin: 0 10px;
  color: orange;
}
</style>

<template>
  <div>
    <div v-if="loading" class="site-container">
      <p>Carregando...</p>
    </div>

    <div v-else>
      <div class="site-container">
        <div class="banner" :style="[imgStyle, { backgroundImage: `url(${item.image})` }]">&nbsp;</div>
      </div>

      <div class="site-container">
        <ul class="path">
          <li>Home</li>
          <li class="sep">&bull;</li>
          <li>{{ item.bloCategoryName }}</li>
          <li class="sep">&bull;</li>
          <li style="color: orange">{{ item.title }}</li>
        </ul>
      </div>

      <div class="site-container mt-2">
        <side-card-blog :item="item" />
      </div>
    </div>

    <!-- BLOG 3 ITEMS -->
    <div class="site-container">
      <card-blog-static />
    </div>

    <div class="site-container mt-4">
      <newsletter-card />
      <Footer />
    </div>
  </div>
</template>

<script>
import BlogService from '@/services/BlogService'
import Footer from '../components/parts/footer/Footer.vue'
import NewsletterCard from '../components/NewsletterCard.vue'

import CardBlogStatic from '../components/cards/CardBlogStatic.vue'
import SideCardBlog from '../components/blog/SideCardBlog.vue'

export default {
  components: {
    CardBlogStatic,
    NewsletterCard,
    SideCardBlog,
    Footer,
  },

  data() {
    return {
      item: {},
      items: [],
      loading: false,
      imgStyle: { height: '50vw', maxHeight: '300px' },
    }
  },

  computed: {
    itemsOthers() {
      return this.items.slice(4)
    },
  },

  async mounted() {
    await this.loadItem()
    await this.loadItems()
  },

  methods: {
    async loadItem() {
      this.loading = true
      const { id } = this.$route.params
      const data = (await BlogService.getItem(id)).data.result
      this.item = { ...data.blogItem, bloCategoryName: data.blogCategoryName }
      this.loading = false
    },

    async loadItems() {
      this.loading = true
      const { items } = (await BlogService.getItemListPublic('MaxResultCount=3')).data.result
      this.items = items.map(m => ({ ...m.blogItem, blogCategoryName: m.blogCategoryName, blogStatusName: m.blogStatusName }))
      this.loading = false
    },
  },
}
</script>
