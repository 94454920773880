<template>
  <div style="height: 1px; background: #eee" :class="`my-${space}`">&nbsp;</div>
</template>

<script>
export default {
  props: {
    space: {
      type: Number,
      default: 4,
    },
  },
}
</script>
